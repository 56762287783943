import React, { useState } from "react";
import Press1 from "../assets/press/press1.png";
import Press2 from "../assets/press/press2.png";
import Press3 from "../assets/press/press3.png";
import Press4 from "../assets/press/press4.png";
import Navbar from "../layout/navbar/Navbar";

const Press = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleClick = (image) => {
    setSelectedImage(image);
  };

  const handleOutsideClick = () => {
    setSelectedImage(null);
  };

  return (
    <>
      <Navbar />
      <section id="presse" className="bg-gray-200 pt-[100px]">
        <h1 className="text-center text-4xl">CÔTÉ PRESSE</h1>
        <div className="pt-12">
          <div className="flex justify-center items-center gap-12 p-12 max-sm:flex-col">
            <img
              onClick={() => handleClick(Press1)}

              className="w-72 h-auto cursor-pointer"
              src={Press1}
              alt=""
            />
            <div>
              <p className="max-w-[600px] leading-9 text-xl">
                La Nouvelle République - Samedi 23 Juillet 2022 - L'invité du samedi
              </p>
              <p className="pt-5 max-w-[600px] leading-9 text-xm">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Suscipit, autem incidunt modi fuga tempora unde assumenda accusantium, libero deleniti, delectus nobis in ea perferendis placeat iure praesentium saepe iste dolores?
              </p>
              <div className="mt-5">
                <a className="text-white bg-blue-500 hover:bg-blue-600 font-semibold py-2 px-4 rounded" href="https://www.lanouvellerepublique.fr/a-la-une/vincent-fernandel-ecoute-sa-curiosite-pour-ne-jamais-se-figer" target="_blank" rel="noopener noreferrer">Lire l'article...</a>
              </div>

            </div>
          </div>
        </div>

        <div className="pt-12">
          <div className="flex-row-reverse flex justify-center items-center gap-12 p-12 max-sm:flex-col">
            <img
              onClick={() => handleClick(Press2)}

              className="w-72 h-auto cursor-pointer"
              src={Press2}
              alt=""
            />
            <div>
              <p className="max-w-[600px] leading-9 text-xl">
                L'Interview de Vincent Fernandel - Retour vers l'essentiel
              </p>
              <p className="pt-5 max-w-[600px] leading-9 text-xm">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Suscipit, autem incidunt modi fuga tempora unde assumenda accusantium, libero deleniti, delectus nobis in ea perferendis placeat iure praesentium saepe iste dolores?
              </p>
              <div className="mt-5">
                <a className="text-white bg-blue-500 hover:bg-blue-600 font-semibold py-2 px-4 rounded" href="https://www.lanouvellerepublique.fr/a-la-une/vincent-fernandel-ecoute-sa-curiosite-pour-ne-jamais-se-figer" target="_blank" rel="noopener noreferrer">Lire l'article...</a>
              </div>

            </div>
          </div>
        </div>

        <div className="pt-12">
          <div className="flex justify-center items-center gap-12 p-12 max-sm:flex-col">
            <img
              onClick={() => handleClick(Press3)}

              className="w-72 h-auto cursor-pointer"
              src={Press3}
              alt=""
            />
            <div>
              <p className="max-w-[600px] leading-9 text-xl">
                OUEST FRANCE - Mercredi 28 Octobre 2020 - Rencontre avec Vincent Fernandel, qui se fait un nom chez les All Blacks.
              </p>
              <p className="pt-5 max-w-[600px] leading-9 text-xm">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Suscipit, autem incidunt modi fuga tempora unde assumenda accusantium, libero deleniti, delectus nobis in ea perferendis placeat iure praesentium saepe iste dolores?
              </p>
              <div className="mt-5">
                <a className="text-white bg-blue-500 hover:bg-blue-600 font-semibold py-2 px-4 rounded" href="https://www.ouest-france.fr/sport/rugby/portrait-vincent-fernandel-petit-fils-de-se-fait-un-nom-chez-les-all-blacks-7031748" target="_blank" rel="noopener noreferrer">Lire l'article...</a>
              </div>

            </div>
          </div>
        </div>

        <div className="pt-12">
          <div className="flex-row-reverse flex justify-center items-center gap-12 p-12 max-sm:flex-col">
            <img
              onClick={() => handleClick(Press4)}

              className="w-72 h-auto cursor-pointer"
              src={Press4}
              alt=""
            />
            <div>
              <p className="max-w-[600px] leading-9 text-xl">
                La Nouvelle République - Mercredi 29 Décembre 2021 - Coulisses - Contes revus et accentués
              </p>
              <p className="pt-5 max-w-[600px] leading-9 text-xm">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Suscipit, autem incidunt modi fuga tempora unde assumenda accusantium, libero deleniti, delectus nobis in ea perferendis placeat iure praesentium saepe iste dolores?
              </p>
              <div className="mt-5">
                <a className="text-white bg-blue-500 hover:bg-blue-600 font-semibold py-2 px-4 rounded" href="https://www.lanouvellerepublique.fr/a-la-une/avec-vincent-fernandel-des-contes-et-des-fables-revus-et-accentues" target="_blank" rel="noopener noreferrer">Lire l'article...</a>
              </div>

            </div>
          </div>
        </div>
      </section>

      {selectedImage && (
        <div
          className="fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-75 flex justify-center items-center"
          onClick={handleOutsideClick}
        >
          <img
            className="max-w-full max-h-full"
            src={selectedImage}
            alt=""
          />
        </div>
      )}
    </>
  );
};

export default Press;
