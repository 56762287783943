import React from "react";
// import HeroPicTest from '../../assets/header/heroImgreel.jpg';
import HeroNewPic from "../../assets/header/heroNew.jpg";

import "./HeroImgStyles.css";

const HeroImg = () => {
  return (
    <div className="hero overflow-hidden" id="hero">
      <div className="mask">
        <img
          className="intro-img"
          src={HeroNewPic}
          alt="Vincent Fernandel ©Sophie Di Malta (NS - mention obligatoire)"
        />
      </div>
      <div className="content font-oswald">
        <h1
          className="content-title"
          style={{ color: "white", textShadow: "4px 2px 4px black" }}
        >
          VINCENT FERNANDEL
        </h1>
        <h2
          className="text-white font-bold text-[40px] max-sm:text-[25px] max-xl:text-[18px]"
          style={{ textShadow: "4px 2px 4px black" }}
        >
          CONTEUR - INTERPRÈTE
        </h2>
      </div>
      <div
        className="absolute text-[18px] max-sm:text-[13px] ml-3 text-white font-oswald pt-[110px] italic 2xl:pt-[140px]"
        style={{ textShadow: "2px 2px 4px black" }}
      >
        © Sophie Di Malta
      </div>
    </div>
  );
};

export default HeroImg;
