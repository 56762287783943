import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './screens/Home';
import About from './screens/About';
import Tales from './screens/Tales';
import Press from './components/Press';
import Medias from './components/Medias';
import Contact from './components/Contact';
import Mentions from './screens/Mentions';
import Confidentiality from './screens/Confidentiality';
import OnScene from './components/OnScene';


function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/biographie' element={<About />} />
          <Route path='/lesenregistrements' element={<Tales />} />
          <Route path='/surscene' element={<OnScene />} />
          <Route path='/presse' element={<Press />} />
          <Route path='/medias' element={<Medias />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/mentions' element={<Mentions />} />
          <Route path='/politiquedeconfidentialite' element={<Confidentiality/>} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
