import React from "react";
import Navbar from "../layout/navbar/Navbar";
import Footer from "../layout/footer/Footer";
import ImgRadio from "../assets/medias/imgradio.jpg";
import ImgTv from "../assets/medias/imgtv.jpg";
import ImgPress from "../assets/medias/imgpress.jpg";
import ImgMedias from "../assets/medias/bannermedia.jpg";
import PdfFile2 from "../assets/medias/PDF/retourverslessentiel.pdf";
import PdfLaProvence from "../assets/pdf/pdflaprovence.pdf"
import { motion } from "framer-motion";
import { fadeIn } from "../variants";

const Medias = () => {
  return (
    <>
      <Navbar />
      <section
        id="medias"
        className="medias pt-[70px] font-oswald bg-gray-200 overflow-hidden"
      >
        <div className="relative">
          <img
            className="w-screen h-[800px] object-cover 2xl:h-[100vh] max-sm:left-[40px]"
            src={ImgMedias}
            alt=""
          />
          <h2
            className="text-[140px] max-xl:text-[80px] max-lg:text-[80px] max-sm:text-[35px] text-white font-oswald absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
            style={{ textShadow: "2px 2px 4px black" }}
          >
            LES MÉDIAS
          </h2>
        </div>

        <div className="pt-[200px] flex flex-col justify-center items-center">
          {/* RADIOS */}
          <div className="pt-10 flex items-center justify-between gap-12 py-8 max-lg:flex-col max-sm:flex-col">
            <motion.div
              variants={fadeIn("right", 0.5)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.3 }}
              className="relative flex-shrink-0"
            >
              <div className=" opacity-[80%]">
                <img
                  className="w-[600px] ml-12 max-sm:ml-0"
                  src={ImgRadio}
                  alt=""
                />
              </div>
              <p className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-[45px] text-white font-bold font-oswald max-sm:text-[30px]">
                CÔTÉ RADIO
              </p>
            </motion.div>
            <motion.ul
              variants={fadeIn("left", 0.5)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.3 }}
              className="text-black flex flex-col list-disc flex-grow text-[18px] leading-10 max-sm:max-w-[90%]"
            >
              {/* Your radio list items go here */}
              <li>
                <a
                  className="font-bold mr-2"
                  href="https://www.francetvinfo.fr/replay-radio/le-monde-d-elodie/vincent-fernandel-petit-fils-de-l-acteur-les-fables-de-la-fontaine-avec-l-accent-marseillais_4203409.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Le monde d’Élodie{" "}
                </a>
                <span className="italic">(France Info - 2020)</span>
              </li>
              <li>
                <a
                  className="font-bold mr-2"
                  href="https://www.youtube.com/watch?v=_D8VyzW1LBU"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Sud Radio Média{" "}
                </a>
                <span className="italic">(Sud Radio - 2021)</span>
              </li>
              <li>
                <a
                  className="font-bold mr-2"
                  href="https://www.rcf.fr/culture/tout-doux?episode=28948"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Tout doux{" "}
                </a>
                <span className="italic">(RCF - 2021)</span>
              </li>
              <li>
                <a
                  className="font-bold mr-2"
                  href="https://www.youtube.com/watch?v=csrSA47lNCo"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Les clefs d’une vie{" "}
                </a>
                <span className="italic">(Sud Radio - 2023)</span>
              </li>
              <li>
                <a
                  className="font-bold mr-2"
                  href="https://www.youtube.com/watch?v=cSoqFoifuSI"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Avec Cyrielle Sarah Cohen{" "}
                </a>
                <span className="italic">(Radio J. - 2023)</span>
              </li>
              <li>
                <a
                  className="font-bold mr-2"
                  href="https://www.youtube.com/watch?v=8dqknXj3i5E"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  En quête de sens{" "}
                </a>
                <span className="italic">(Radio Notre Dame - 2023)</span>
              </li>
              <li>
                <a
                  className="font-bold mr-2"
                  href="https://www.francetvinfo.fr/replay-radio/le-monde-d-elodie/nicolas-pagnol-et-vincent-fernandel-evoquent-leurs-grands-peres-respectifs-deux-monstres-sacres-avec-deux-caracteres-impossibles_6118275.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Le monde d’Élodie{" "}
                </a>
                <span className="italic">(France Info - 2023)</span>
              </li>
              <li>
                <a
                  className="font-bold mr-2"
                  href="https://www.francebleu.fr/emissions/le-quart-d-heure-de-celebrite/vincent-fernandel-nous-plonge-dans-l-univers-de-pagnol-avec-un-livre-audio-8400897"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Le quart d’heure célébrité{" "}
                </a>
                <span className="italic">(France Bleu Azur - 2024)</span>
              </li>
              <li>
                <a
                  className="font-bold mr-2"
                  href="https://www.francebleu.fr/emissions/la-scene-culture/le-livre-cd-marcel-pagnol-lu-par-vincent-fernandel-4113809"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  La scène culture{" "}
                </a>
                <span className="italic">(France Bleu national - 2024)</span>
              </li>
              <li>
                <a
                  className="font-bold mr-2"
                  href="https://www.radiofrance.fr/francemusique/podcasts/etonnez-moi-benoit/souvenirs-souvenirs-avec-vincent-fernandel-nicolas-pagnol-les-petits-fils-2664278"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Étonnez-moi, Benoit !{" "}
                </a>
                <span className="italic">(France Musique - 2024)</span>
              </li>
            </motion.ul>
          </div>

          {/* BORDER */}
          <div className="border border-black my-[120px] w-[300px] mx-auto"></div>

          {/* TELE */}
          <div className="flex flex-row-reverse justify-center items-center gap-12 py-8 max-lg:flex-col max-sm:flex-col">
            <motion.div
              variants={fadeIn("right", 0.5)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.3 }}
              className="relative flex-shrink-0"
            >
              <div className=" opacity-[80%]">
                <img className="w-[600px] max-sm:ml-0" src={ImgTv} alt="" />
              </div>
              <p className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-[45px] text-white font-bold font-oswald max-sm:text-[30px]">
                CÔTÉ TÉLÉ
              </p>
            </motion.div>
            <motion.ul
              variants={fadeIn("left", 0.5)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.3 }}
              className="text-black flex flex-col list-disc flex-grow text-[18px] leading-10 max-sm:max-w-[90%]"
            >
              {/* Your radio list items go here */}
              <li>
                <a
                  className="font-bold mr-2"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.france.tv/france-4/la-maison-des-parents/2573353-grandir-avec-une-star-dans-la-famille-on-se-dit-tout.html"
                >
                  La Maison des parents{" "}
                </a>
                <span className="italic">(France 4 - 2021)</span>
              </li>
              <li>
                <a
                  className="font-bold mr-2"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.youtube.com/watch?v=_CTx8Hq5VrM"
                >
                  Vous êtes formidables !{" "}
                </a>
                <span className="italic">(France 3 - 2023)</span>
              </li>
              <li>
                <a
                  className="font-bold mr-2"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.youtube.com/watch?v=Dh5zyjCCQrY"
                >
                  Marcel Pagnol, d’un siècle à l’autre{" "}
                </a>
                <span className="italic">(Nice Matin TV - 2024)</span>
              </li>
            </motion.ul>
          </div>

          {/* BORDER */}
          <div className="border border-black my-[120px] w-[300px] mx-auto"></div>

          {/* PRESSE */}
          <div className=" pt-10 flex items-center justify-between gap-12 py-8 max-lg:flex-col max-sm:flex-col">
            <motion.div
              variants={fadeIn("right", 0.5)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.3 }}
              className="relative flex-shrink-0"
            >
              <div className="opacity-[80%]">
                <img
                  className="w-[600px] ml-12 max-sm:ml-0"
                  src={ImgPress}
                  alt=""
                />
              </div>

              <p className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-[45px] font-oswald  text-white font-bold  max-sm:text-[30px]">
                CÔTÉ PRESSE
              </p>
            </motion.div>
            <motion.ul
              variants={fadeIn("left", 0.5)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.3 }}
              className="text-black flex flex-col list-disc flex-grow text-[18px] leading-10 max-sm:max-w-[90%]"
            >
              {/* Your press list items go here */}
              <li>
                <a
                  className="font-bold"
                  href={PdfFile2}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Var matin -{" "}
                </a>
                <span className="italic">2020</span>
              </li>
              <li>
                <a
                  className="font-bold"
                  href="https://www.ouest-france.fr/sport/rugby/portrait-vincent-fernandel-petit-fils-de-se-fait-un-nom-chez-les-all-blacks-7031748"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Ouest France -{" "}
                </a>
                <span className="italic">2020</span>
              </li>
              <li>
                <a
                  className="font-bold"
                  href="https://www.lanouvellerepublique.fr/a-la-une/avec-vincent-fernandel-des-contes-et-des-fables-revus-et-accentues"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  La nouvelle république -{" "}
                </a>
                <span className="italic">2021</span>
              </li>
              <li>
                <a
                  className="font-bold"
                  href="https://www.nicematin.com/cinema/-c-est-comme-si-fernandel-n-avait-jamais-existe-le-coup-de-gueule-de-vincent-fernandel-866668"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Nice Matin -{" "}
                </a>
                <span className="italic">2023</span>
              </li>
              <li>
                <a
                  className="font-bold"
                  href="https://www.lamarseillaise.fr/culture/apres-daudet-vincent-fernandel-lit-pagnol-IJ14843139"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  La marseillaise -{" "}
                </a>
                <span className="italic">2023</span>
              </li>
              <li>
                <a
                  className="font-bold"
                  href="https://www.lanouvellerepublique.fr/a-la-une/quand-fernandel-raconte-les-grandes-oeuvres-de-pagnol"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  La nouvelle république -{" "}
                </a>
                <span className="italic">2023</span>
              </li>
              <li>
                <a
                  className="font-bold"
                  href="https://www.lefigaro.fr/cinema/pagnol-et-fernandel-les-variations-d-amour-de-leurs-petits-fils-20240630
                  "
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Le Figaro -{" "}
                </a>
                <span className="italic">2024</span>
              </li>
              <li>
                <a
                  className="font-bold"
                  href="https://www.lejdd.fr/culture/vincent-fernandel-et-nicolas-pagnol-au-jdd-est-deja-comme-un-vieux-couple-147052"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Le Journal du dimanche -{" "}
                </a>
                <span className="italic">2024</span>
              </li>
              <li>
                <a
                  className="font-bold"
                  href="https://www.lire.fr/products/marcel-pagnol"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Lire Magazine -{" "}
                </a>
                <span className="italic">2024</span>
              </li>
              <li>
                <a
                  className="font-bold"
                  href={PdfLaProvence}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  La Provence -{" "}
                </a>
                <span className="italic">2024</span>
              </li>
            </motion.ul>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Medias;
