import React from 'react'
import Navbar from '../layout/navbar/Navbar'
import HeroImg from '../layout/header/HeroImg'
import Presentation from '../components/Presentation'
import NetWorks from '../components/Networks'
import Footer from '../layout/footer/Footer'
import Rubrics from '../components/Rubrics'
import News from '../components/News'

const Home = () => {
  return (
    <>
      <Navbar />
      <HeroImg />
      <Presentation />
      <News />
      <Rubrics />
      <NetWorks />
      <Footer />
    </>
  )
}

export default Home