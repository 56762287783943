import React from "react";
import Navbar from "../layout/navbar/Navbar";
import ImgAbout from "../assets/about/aboutnew.jpg";
import ImgAbout2 from "../assets/about/aboutpicture.jpg";
import Footer from "../layout/footer/Footer";

const About = () => {
  return (
    <>
      <Navbar />
      <section id="a-propos" className="h-full bg-gray-200 overflow-hidden">
        <div className="flex flex-col">
          <div className="flex flex-col pt-[70px] justify-center items-center">
            <div className="relative">
              <img
                className="w-screen h-[800px] object-cover 2xl:h-[110vh] max-sm:object-[60%_center]"
                src={ImgAbout}
                alt=""
              />
              <h2
                className="text-[140px] max-xl:text-[80px] max-lg:text-[80px] max-sm:text-[35px] text-white font-oswald absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                style={{ textShadow: "2px 2px 4px black" }}
              >
                BIOGRAPHIE
              </h2>
              <p
                className="absolute bottom-0 left-0 text-white font-oswald italic text-[18px] max-sm:text-[13px] pl-4 pb-4"
                style={{ textShadow: "2px 2px 4px black" }}
              >
                © Sophie Di Malta
              </p>
            </div>

            <div className="flex flex-row-reverse gap-x-10 ">
              <div className="py-10 max-sm:mx-auto">
                <div>
                  <div className="flex gap-x-10 mt-10 max-sm:flex-col-reverse pt-0 max-lg:flex-col-reverse">
                    <ul className="mt-6 text-justify flex flex-col justify-center items-center">
                      <li className="py-2 text-[18px] leading-10">
                        <p className="max-w-[700px] max-sm:max-w-[60%] mx-auto font-oswald">
                          Né dans une famille d’artistes, Vincent Fernandel se
                          forme aux métiers de l’image, à la direction d’acteur
                          ainsi qu’à l’écriture scénaristique. Il débute sa
                          carrière dans les médias, comme animateur télé et
                          journaliste spécialisé dans le cinéma, réalisant ainsi
                          près de 400 sujets sur le 7ème art contemporain et
                          patrimonial.{" "}
                        </p>
                      </li>
                      <li className="text-[18px] leading-10">
                        <p className="max-w-[700px] max-sm:max-w-[60%] mx-auto font-oswald ">
                          Il se consacre ensuite au théâtre pendant dix ans en
                          tant que formateur d’acteurs. Auteur, il écrit deux
                          pièces avec l’actrice Élisa Ollier,{" "}
                          <span className="font-medium	">
                            Anna attend l’amour
                          </span>{" "}
                          et <span className="font-medium">Mona</span>, toutes
                          deux créées à Paris. Vincent signe également le livre{" "}
                          <span className="font-medium	">
                            Au cœur de la fougère
                          </span>
                          , en collaboration avec le journaliste Ian Borthwick,
                          sur la Nouvelle-Zélande et la mythique équipe des All
                          Blacks. Puis, en devenant parolier pour le chanteur
                          Florent Richard, il décide de fonder sa propre société
                          de production et d’édition musicale,{" "}
                          <a
                            className="font-semibold underline rounded"
                            href="https://vincentfernandelproduction.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Vincent Fernandel Production
                          </a>{" "}
                          , dédiée aux artistes francophones. Ainsi, Vincent
                          réacquiert plusieurs chansons interprétées par son
                          père, Franck Fernandel, parmi lesquelles des succès et
                          des inédits, pour les proposer en versions
                          remasterisées sur toutes les plateformes musicales.
                        </p>
                      </li>
                      <li className="text-[18px] leading-10">
                        <p className="max-w-[700px] max-sm:max-w-[60%] mx-auto font-oswald pt-3 ">
                          Depuis 2019, Vincent place son métier de conteur au
                          cœur de ses activités : il enregistre les œuvres de
                          Jean de La Fontaine, Alphonse Daudet ou encore Marcel
                          Pagnol, en version livre-cd, pour la jeunesse aux
                          Éditions Fleurus, et pour tout public chez Wagram
                          Music et Audiolib. Il donne régulièrement des lectures
                          en milieu scolaire et dans le cadre d’événements
                          culturels.
                        </p>
                      </li>

                      <li className="text-[18px] leading-10">
                        <p className="max-w-[690px] max-sm:max-w-[60%] mx-auto font-oswald pt-3">
                          En 2024, Nicolas Pagnol imagine une lecture musicale
                          autour des plus beaux textes de son grand-père,
                          intitulée{" "}
                          <span className="font-medium	">
                            Marcel Pagnol, variations d’amour
                          </span>
                          . Il en confie l’interprétation à Vincent, accompagné
                          sur scène par le pianiste et compositeur Franck Ciup.
                          <span className="font-medium">
                            {" "}
                            Marcel Pagnol, variations d’amour
                          </span>{" "}
                          est actuellement en tournée.
                        </p>
                      </li>
                    </ul>
                    <img
                      className="max-w-[550px] object-cover max-sm:max-w-[300px] max-sm:mx-auto max-lg:mx-auto 2xl:max-w-[850px]"
                      src={ImgAbout2}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default About;
