import React from "react"
import { GiPlatform } from 'react-icons/gi'
import { BsSpotify } from 'react-icons/bs'
import { FaDeezer } from 'react-icons/fa'
import { SiApplemusic } from 'react-icons/si'
import { motion } from 'framer-motion'
import { fadeIn } from '../variants'

const NetWorks = () => {
  return (
    <div className="text-white h-[35vh] max-sm:h-full max-sm:py-8 max-sm:pt-5 flex flex-col justify-center items-center overflow-hidden " style={{backgroundColor: '#131114'}}>
      <GiPlatform size={70} />
      <div className="flex items-center gap-5 mt-5">
        <motion.h2
          variants={fadeIn('left', 0.3)}
          initial="hidden"
          whileInView={'show'}
          viewport={{ once: false, amount: 0.3 }}
          className="text-[25px] font-oswald max-sm:text-xl max-sm:text-center">Suivez Vincent Fernandel sur les plateformes</motion.h2>
      </div>
      <motion.ul
        variants={fadeIn('right', 0.5)}
        initial="hidden"
        whileInView={'show'}
        viewport={{ once: false, amount: 0.3 }}
        className="flex gap-10 mt-10 max-sm:flex-col">
        <li className="flex items-center gap-3 bg-white hover:bg-blue-600 font-semibold py-2 px-3 rounded ">
          <a className=" text-black" href="https://open.spotify.com/intl-fr/artist/5sfXUkWtypVNnZHGVtXvoV" target="_blank" rel="noopener noreferrer">Spotify</a>
          <BsSpotify size={30} color="#1DB954
" />
        </li>
        <li className="flex items-center gap-3  bg-white hover:bg-blue-600 font-semibold py-2 px-4 rounded">
          <a className=" text-black" href="https://www.deezer.com/fr/artist/186946287 " target="_blank" rel="noopener noreferrer">Deezer</a>
          <FaDeezer size={30} color="#FF0000" />
        </li>
        <li className="flex items-center gap-3  bg-white hover:bg-blue-600 font-semibold py-2 px-4 rounded">
          <a className=" text-black" href="https://music.apple.com/fr/artist/vincent-fernandel/1649564681" target="_blank" rel="noopener noreferrer">Apple Music</a>
          <SiApplemusic size={30} color="#c2cad7" />
        </li>
      </motion.ul>
    </div>
  )
}

export default NetWorks
