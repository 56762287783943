import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { fadeIn } from '../variants';

const Presentation = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const presentationStyles = {
    // backgroundColor: '#131114',
    backgroundColor: '#232931',
    position: 'relative',
    zIndex: scrolled ? 11 : 10, // Augmentez le z-index lorsque vous faites défiler la page
  };

  return (
    <div className='h-full py-10 overflow-hidden text-white font-oswald' style={presentationStyles}>
      <div className='flex flex-col items-center justify-center'>
        <motion.p
          variants={fadeIn('down', 0.5)}
          initial="hidden"
          whileInView={'show'}
          viewport={{ once: false, amount: 0.3 }}
          className='text-[20px] max-sm:text-[16px] text-justify mx-auto max-w-[600px] max-sm:w-[80%] leading-[3] '>
          « Dans cette époque où le développement de la technologie virtuelle bouleverse notre capacité à rêver, à prendre le temps de penser le monde, conter devient, dans une certaine mesure, un acte nécessaire. C’est la volonté d’entretenir les passerelles du temps, de garder aux mots leur rôle premier de partage et de transmission. En somme, le contraire de l’information de masse basée sur un flot incessant de mots jetés en tous sens, in fine désincarnés et vidés de leur substance. Le conteur, grâce à cet outil vibrant qu’est la voix, travaille, mot à mot, à rendre visible les histoires, pour faire traverser l’irréel, la fiction, mais aussi le souvenir, développant ainsi nos mondes intérieurs. Si écrire est un moyen d’échapper au temps, dire est une façon de s’y ancrer. Ceci est ma conviction ferme et sensible. Naturellement, mon premier moteur reste le plaisir de prêter ma voix aux mots des auteurs que j’aime, en espérant qu’ils éveilleront et réveilleront un imaginaire dont chacun de nous, quel que soit son âge, aura toujours besoin. »
        </motion.p>
        <p className='text-[25px] mt-5'>Vincent Fernandel</p>
      </div>
    </div>
  );
}

export default Presentation;
