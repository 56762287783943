import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AiOutlineArrowDown } from "react-icons/ai";
import PosterImg from "../assets/news/posternewmp.jpeg";
import Tale1 from "../assets/tales/talepagnol.jpg";
import Tale2 from "../assets/tales/jeandeflorette.jpeg";
import Tale3 from "../assets/tales/manondessources.jpg";
import { motion } from "framer-motion";

const News = () => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsVisible((prevVisible) => !prevVisible);
    }, 500); // Réglez la durée du clignotement en millisecondes

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div
      className="max-sm:pt-[30px] max-sm:h-[100%] md:h-[100%] lg:h-full py-6 flex items-center justify-center h-[80vh] bg-gray-200 text-black max-sm:flex-col overflow-hidden"
      id="news"
    >
      <div className="flex flex-col">
        <h2 className="text-center font-oswald text-[50px] mb-10 pt-5 max-sm:text-[30px]">
          ACTUALITÉS
        </h2>

        <div className="flex justify-center">
          <div className="flex flex-col gap-y-[100px]">
            {/* NEW 1 */}
            <div className=" w-full">
              <div className="flex max-sm:pt-5 gap-9 justify-center max-sm:flex-col">
                <div>
                  <img
                    className="w-[400px] max-sm:mx-auto max-sm:max-w-[300px]"
                    src={PosterImg}
                    alt=""
                  />
                </div>

                <div className="flex flex-col max-sm:items-center">
                  <motion.h1
                    className="text-[60px] text-center font-oswald max-sm:text-[30px]"
                    variants={{
                      hidden: { opacity: 0 },
                      show: {
                        opacity: 1,
                        transition: { duration: 0.5, yoyo: Infinity },
                      },
                    }}
                    initial="hidden"
                    animate={isVisible ? "show" : "hidden"}
                    viewport={{ once: false, amount: 0.3 }}
                  >
                    À LA UNE
                  </motion.h1>
                  <div className="pt-10">
                    <h2 className="text-[30px] max-sm:text-[25px] text-center text-black font-oswald">
                      Actuellement sur scène !
                    </h2>
                    <div className="max-w-[300px]">
                      <h2 className="text-[20px] text-center font-oswald">
                        <span className="font-medium">Marcel Pagnol, variations d’amour</span> est une lecture
                        musicale imaginée par Nicolas Pagnol, interprétée par
                        Vincent Fernandel et Franck Ciup.
                        <br /> En tournée dans toute la France.
                      </h2>
                    </div>

                    <div className="flex flex-col items-center justify-start">
                      <AiOutlineArrowDown className="text-[50px] mt-10 max-sm:text-[30px] animate-bounce" />

                      <div className="mt-5 text-base">
                        <Link
                          className="text-white bg-black hover:bg-blue-600 font-semibold py-2 px-4 rounded"
                          to="/surscene"
                        >
                          En savoir plus
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* NEW 2 */}
            <div className=" w-full">
              <div className="flex max-sm:pt-5 gap-9 justify-center max-sm:flex-col">
                <div>
                  <img
                    className="w-[300px] max-sm:mx-auto max-sm:max-w-[300px] mr-2"
                    src={Tale2}
                    alt=""
                  />
                  <img
                    className="w-[300px] pt-5 max-sm:mx-auto max-sm:max-w-[300px] mr-2"
                    src={Tale3}
                    alt=""
                  />
                </div>

                <div className="flex flex-col max-sm:items-center">
                  <div className="pt-10">
                    <h2 className="text-[30px] max-sm:text-[25px] text-center text-black font-oswald">
                      Nouveaux livres-audio
                    </h2>
                    <h2 className="text-[20px] text-center font-oswald">
                      <span className="font-medium">« Jean de Florette »</span> et <span className="font-medium	">« Manon des sources »</span> <br /> de
                      Marcel Pagnol
                      <br /> lus par Vincent Fernandel
                    </h2>
                    <div className="flex flex-col items-center justify-start">
                      <AiOutlineArrowDown className="text-[50px] mt-10 max-sm:text-[30px] animate-bounce" />

                      <div className="mt-5 text-base">
                        <a
                          href="https://www.audiolib.fr/auteur/vincent-fernandel/"
                          className="text-white bg-black hover:bg-blue-600 font-semibold py-2 px-4 rounded"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Commander
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* NEW 3 */}
            <div className=" w-full">
              <div className="flex max-sm:pt-5 gap-9 justify-center max-sm:flex-col">
                <div>
                  <img
                    className="w-[400px] max-sm:mx-auto max-sm:max-w-[300px]"
                    src={Tale1}
                    alt=""
                  />
                </div>

                <div className="flex flex-col max-sm:items-center">
                  <div className="pt-10">
                    <h2 className="text-[30px] max-sm:text-[25px] text-center text-black font-oswald">
                      Nouveau livre-cd
                    </h2>
                    <h2 className="text-[20px] text-center font-oswald">
                      Marcel Pagnol lu par Vincent Fernandel
                    </h2>
                    <div className="flex flex-col items-center justify-start">
                      <AiOutlineArrowDown className="text-[50px] mt-10 max-sm:text-[30px] animate-bounce" />

                      <div className="mt-5 text-base">
                        <a
                          href="https://www.amazon.fr/Marcel-Pagnol-Vincent-Fernandel-livre-CD/dp/2215179449/ref=sr_1_1?crid=178VIC60XURZA&keywords=pagnol+vincent+fernandel&qid=1694421083&sprefix=%252Caps%252C48&sr=8-1"
                          className="text-white bg-black hover:bg-blue-600 font-semibold py-2 px-4 rounded"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Commander
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default News;
