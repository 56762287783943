import React from "react";
import Navbar from "../layout/navbar/Navbar";
import Footer from "../layout/footer/Footer";

const Confidentiality = () => {
  return (
    <>
      <Navbar />
      <section
        className="pt-[100px] text-justify font-oswald text-white"
        style={{ backgroundColor: "#131114" }}
      >
        <div className="container mx-auto py-8 max-w-[600px] max-sm:max-w-[70%]">
          <h1 className="text-3xl font-bold mb-4">
            Politique de confidentialité
          </h1>

          <div className="mb-8">
            <h2 className="text-xl font-bold mb-2">
              Achats via des liens externes
            </h2>
            <p>
              Certains contenus présentés sur ce site sont accompagnés de
              boutons "acheter" qui redirigent vers des plateformes de vente en
              ligne, telles que [Amazon, Fnac]. Lorsque vous cliquez sur ces
              liens, vous quittez notre site et êtes redirigé vers la plateforme
              respective. Veuillez noter que vos informations personnelles
              collectées lors de vos achats sur ces plateformes sont régies par
              les conditions générales d'utilisation propres à chaque
              plateforme.
            </p>
          </div>

          <div className="mb-8">
            <h2 className="text-xl font-bold mb-2">
              Collecte des informations
            </h2>
            <p>
              Nous collectons certaines informations vous concernant lors de
              votre utilisation du site www.vincent-fernandel.com. Cette
              collecte peut inclure des données personnelles telles que votre
              nom, votre adresse e-mail, etc.
            </p>
          </div>

          <div className="mb-8">
            <h2 className="text-xl font-bold mb-2">
              Utilisation des informations
            </h2>
            <p>
              Les informations que nous collectons sont utilisées pour vous
              fournir des informations pertinentes sur les activités de Vincent
              Fernandel. Nous ne partageons pas ces informations avec des tiers
              sans votre consentement.
            </p>
          </div>

          <div className="mb-8">
            <h2 className="text-xl font-bold mb-2">Sécurité</h2>
            <p>
              Nous nous engageons à assurer la sécurité de vos informations
              personnelles. Des mesures de sécurité appropriées sont mises en
              place pour protéger contre l'accès non autorisé, la divulgation,
              l'altération ou la destruction des données.
            </p>
          </div>

          <div className="mb-8">
            <h2 className="text-xl font-bold mb-2">
              Modification de la politique de confidentialité
            </h2>
            <p>
              Nous nous réservons le droit de modifier la présente politique de
              confidentialité à tout moment. Les modifications prendront effet
              dès leur publication sur le site.
            </p>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Confidentiality;
