import React from "react";
import { BsSpotify } from "react-icons/bs";
import Navbar from "../layout/navbar/Navbar";
import Tale1 from "../assets/tales/talefa.jpg";
import Tale2 from "../assets/tales/talecla.jpg";
import Tale3 from "../assets/tales/talelet1.jpg";
import Tale4 from "../assets/tales/talelet2.jpg";
import Tale5 from "../assets/tales/talelet3.jpg";
import Tale6 from "../assets/tales/daudet.jpeg";
import Tale7 from "../assets/tales/talepagnol.jpg";
import Tale8 from "../assets/tales/jeandeflorette.jpeg";
import Tale9 from "../assets/tales/manondessources.jpg";
import Footer from "../layout/footer/Footer";
import ImgTales from "../assets/tales/bannertaletest.jpg";
import { motion } from "framer-motion";
import { fadeIn } from "../variants";

const Tales = () => {
  return (
    <>
      <Navbar />
      <section id="contes" className="contes overflow-hidden bg-gray-200">
        <div className="relative pt-[70px]">
          <img
            className="w-screen h-[800px] object-cover 2xl:h-[100vh]"
            src={ImgTales}
            alt=""
          />
          <div className="">
            <h2
              className="text-[140px] w-full text-center max-xl:text-[70px] max-lg:text-[80px] max-sm:text-[35px] text-white font-oswald absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
              style={{ textShadow: "2px 2px 4px black" }}
            >
              LES ENREGISTREMENTS
            </h2>
          </div>
        </div>

        <div className="pt-[50px]">
          <h2 className="font-kalam text-center text-3xl max-sm:text-[25px]">
            « Jean de Florette » de Marcel Pagnol lu par Vincent Fernandel
            <br />
            (L'eau des collines, tome 1)
          </h2>
          <div className="flex justify-center items-center gap-12 p-12 max-sm:flex-col">
            <motion.div
              variants={fadeIn("right", 0.5)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.3 }}
              className="flex flex-col items-center"
            >
              <img className="w-[300px] h-auto" src={Tale8} alt="" />
            </motion.div>

            <motion.div
              variants={fadeIn("left", 0.5)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.3 }}
            >
              <p className="max-w-[600px] font-oswald  leading-9 text-[18px] text-justify">
                <span className="text-[20px] text-gray-600 font-bold">
                  Jean de Florette, publié en 1962, est le premier volume de
                  L’Eau des collines, épopée provençale, inspirée par le film
                  réalisé par Pagnol dix ans plus tôt, Manon des sources,
                  l’histoire de la fille de Jean.
                </span>
                <br />
                Les dialogues savoureux, la prose limpide et l’art du portrait
                de Marcel Pagnol font de ce roman une merveille qui traverse les
                décennies. Vincent Fernandel fait revivre avec verve ces
                personnages taillés à la serpe.
              </p>
              <p className="max-w-[600px] font-oswald  leading-9 text-[18px] text-justify pt-6">
                Avec le soutien du CNL (Centre National du Livre).
              </p>
              <p className="max-w-[600px] font-oswald  leading-9 text-[18px] text-justify">
                Musique composée par Michel Korb.
              </p>
              <div className="flex gap-5 mt-10 max-sm:flex-col max-lg:flex-col items-center">
                <div>
                  <a
                    className=" text-white bg-blue-500 hover:bg-blue-600 font-semibold py-2 px-2 rounded"
                    href="https://www.audiolib.fr/livre/jean-de-florette-9791035414788/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Acheter en version numérique et CD
                  </a>
                </div>
                <div></div>
              </div>
            </motion.div>
          </div>
        </div>

        <div className="pt-[50px]">
          <h2 className="font-kalam text-center text-3xl max-sm:text-[25px]">
            « Manon des sources » de Marcel Pagnol lu par Vincent Fernandel
            <br />
            (L’eau des collines, tome 2){" "}
          </h2>
          <div className="flex justify-center items-center gap-12 p-12 max-sm:flex-col">
            <motion.div
              variants={fadeIn("right", 0.5)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.3 }}
              className="flex flex-col items-center"
            >
              <img className="w-[300px] h-auto" src={Tale9} alt="" />
            </motion.div>

            <motion.div
              variants={fadeIn("left", 0.5)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.3 }}
            >
              <p className="max-w-[600px] font-oswald  leading-9 text-[18px] text-justify">
                <span className="text-[20px] text-gray-600 font-bold">
                  Publié en 1963, un an après Jean de Florette, roman du drame
                  fondateur, Manon des sources est le roman de la vengeance et
                  de l’amour. C’est aussi la « mise en roman » du film éponyme
                  tourné dix ans plus tôt.
                </span>
                <br />
                Vincent Fernandel fait sien cet hymne à la Provence et à la
                vitalité d’une jeune femme, sauvageonne, sorcière ou fée des
                sources.
              </p>
              <p className="max-w-[600px] font-oswald  leading-9 text-[18px] text-justify pt-6">
                Avec le soutien du CNL (Centre National du Livre).
              </p>
              <p className="max-w-[600px] font-oswald  leading-9 text-[18px] text-justify">
                Musique composée par Michel Korb.
              </p>
              <div className="flex gap-5 mt-10 max-sm:flex-col max-lg:flex-col items-center">
                <div>
                  <a
                    className="text-white bg-blue-500 hover:bg-blue-600 font-semibold py-2 px-2 rounded flex"
                    href="https://www.audiolib.fr/livre/manon-des-sources-9791035414276/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Acheter en version numérique et CD 
                  </a>
                </div>
              </div>
            </motion.div>
          </div>
        </div>

        <div className="pt-[100px]">
          <h2 className="font-kalam text-center text-3xl max-sm:text-[25px]">
            Marcel Pagnol lu par Vincent Fernandel
          </h2>
          <div className="flex-row-reverse flex justify-center items-center gap-12 p-12 max-sm:flex-col">
            <motion.div
              variants={fadeIn("left", 0.5)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.3 }}
              className="flex flex-col items-center"
            >
              <img className="w-[300px] h-auto" src={Tale7} alt="" />
            </motion.div>
            <motion.div
              variants={fadeIn("right", 0.5)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.3 }}
            >
              <p className="max-w-[600px] font-oswald  leading-9 text-[18px] text-justify">
                <span className="font-oswald text-[20px] text-gray-600 font-bold">
                  Extraits de « La gloire de mon père », « Le château de ma
                  mère » et « Le temps des secrets »{" "}
                </span>
                <br />
                Pour une première découverte de ce grand patrimoine littéraire,
                Vincent Fernandel raconte en musique, et avec l’accent, les plus
                beaux passages des « Souvenirs d’enfance » de Marcel Pagnol,
                illustrés par les dessins ensoleillés de Gérald Guerlais.
              </p>
              <div className="mt-10 max-sm:text-center">
                <a
                  className="text-white  bg-blue-500 hover:bg-blue-600 font-semibold py-2 px-4 rounded"
                  href="https://www.amazon.fr/Marcel-Pagnol-Vincent-Fernandel-livre-CD/dp/2215179449/ref=sr_1_1?crid=178VIC60XURZA&keywords=pagnol+vincent+fernandel&qid=1694421083&sprefix=%252Caps%252C48&sr=8-1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Acheter le livre-cd
                </a>
              </div>
            </motion.div>
          </div>
        </div>

        <div className="pt-[50px]">
          <h2 className="font-kalam text-center text-3xl max-sm:text-[25px]">
            Les Fables de La Fontaine
          </h2>
          <div className="flex justify-center items-center gap-12 p-12 max-sm:flex-col">
            <motion.div
              variants={fadeIn("right", 0.5)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.3 }}
              className="flex flex-col items-center"
            >
              <img className="w-[300px] h-auto" src={Tale1} alt="" />
              <div className="mt-5 flex items-center gap-3 bg-blue-500 hover:bg-blue-600 font-semibold py-2 px-2 rounded max-w-[200px]">
                <a
                  className=" text-white"
                  href="https://open.spotify.com/intl-fr/album/6nx8wFy4MDR9BWi6I2cf5U"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Écouter sur Spotify
                </a>
                <BsSpotify size={20} color="#1DB954" />
              </div>
            </motion.div>

            <motion.div
              variants={fadeIn("left", 0.5)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.3 }}
            >
              <p className="max-w-[600px] font-oswald  leading-9 text-[18px] text-justify">
                <span className="text-[20px] text-gray-600 font-bold">
                  La Cigale et la Fourmi, Le Loup et L’Agneau, Le Lièvre et la
                  Tortue...
                </span>
                <br />
                Retrouvez les 17 plus belles fables de Jean de La
                Fontaine compilées dans un magnifique album, racontées par
                Vincent Fernandel et illustrées avec élégance par Charlotte
                Molas.
              </p>
              <div className="flex gap-5 mt-10 max-sm:flex-col max-lg:flex-col items-center">
                <div>
                  <a
                    className=" text-white bg-blue-500 hover:bg-blue-600 font-semibold py-2 px-2 rounded"
                    href="https://www.amazon.fr/Fables-Fontaine-1CD-audio/dp/2215159073"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Acheter la version livre-cd
                  </a>
                </div>
                <div>
                  <a
                    className="text-white bg-blue-500 hover:bg-blue-600 font-semibold py-2 px-2 rounded"
                    href="https://www.fnac.com/a17226508/Collectif-Collection-Vinylbook-Liste-des-Fables-de-La-Fontaine-Vinyle-album "
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Acheter le Vinylbook collector
                  </a>
                </div>
              </div>
            </motion.div>
          </div>
        </div>

        <div className="pt-[100px]">
          <h2 className="font-kalam text-center text-3xl max-sm:text-[25px]">
            Les Contes Classiques
          </h2>
          <div className="flex-row-reverse flex justify-center items-center gap-12 p-12 max-sm:flex-col">
            <motion.div
              variants={fadeIn("left", 0.5)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.3 }}
              className="flex flex-col items-center"
            >
              <img className="w-[300px] h-auto" src={Tale2} alt="" />
              <div className="mt-5 flex items-center gap-3 bg-blue-500 hover:bg-blue-600 font-semibold py-2 px-2 rounded max-w-[200px]">
                <a
                  className=" text-white"
                  href="https://open.spotify.com/intl-fr/album/35kwkBm6hBEkpqb7nri5Ti "
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Écouter sur Spotify
                </a>
                <BsSpotify size={20} color="#1DB954" />
              </div>
            </motion.div>
            <motion.div
              variants={fadeIn("right", 0.5)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.3 }}
            >
              <p className="max-w-[600px] font-oswald  leading-9 text-[18px] text-justify">
                <span className="text-[20px] text-gray-600 font-bold">
                  Jack et le haricot magique, Peau d’âne, Poucette...
                </span>
                <br />
                Redécouvrez six contes traditionnels interprétés par Vincent
                Fernandel accompagné par les dessins féériques de Charlotte
                Molas.
              </p>
              <div className="mt-10 max-sm:text-center">
                <a
                  className="text-white  bg-blue-500 hover:bg-blue-600 font-semibold py-2 px-4 rounded"
                  href="https://www.amazon.fr/contes-classiques-racont%25C3%25A9s-Vincent-Fernandel/dp/221517711X/ref=sr_1_3?qid=1689182220&refinements=p_27%253AVincent+Fernandel&s=books&sr=1-3&text=Vincent+Fernandel"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Acheter le livre-cd
                </a>
              </div>
            </motion.div>
          </div>
        </div>

        <div className="pt-[100px]">
          <h2 className="font-kalam text-center text-black text-3xl max-sm:text-[25px]">
            Les Lettres De Mon Moulin
          </h2>
          <div className="max-sm:flex-col flex justify-center gap-12 p-12">
            <motion.div
              variants={fadeIn("right", 0.5)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.3 }}
              className="flex flex-col gap-7 max-sm:flex-col items-center"
            >
              <img className="w-[300px] h-auto" src={Tale3} alt="" />
              <img className="w-[300px] h-auto" src={Tale4} alt="" />
              <img className="w-[300px] h-auto" src={Tale5} alt="" />
              <div className="mt-2 flex items-center gap-3 bg-blue-500 hover:bg-blue-600 font-semibold py-2 px-2 rounded max-w-[200px]">
                <a
                  className=" text-white"
                  href="https://open.spotify.com/intl-fr/album/6Na9yddaJGwCU9nTz7x0UU "
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Écouter sur Spotify
                </a>
                <BsSpotify size={20} color="#1DB954" />
              </div>
            </motion.div>

            <motion.div
              variants={fadeIn("left", 0.5)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.3 }}
            >
              <p className="text-black max-w-[700px] font-oswald  leading-9 text-[18px] text-justify">
                La Provence est un joyau dont seuls les provençaux savent
                parler. Les mots du Midi se transmettent de bouche en bouche, de
                génération en génération, comme un miel précieux. Une tradition
                orale que chérissent ceux qui, à force de les lire, ne peuvent
                s’empêcher de raconter. Et en Provence, raconter, c’est faire
                exister. Parmi ces pépites venues du pays du Soleil, il y a les
                Lettres de Mon Moulin, d’Alphonse Daudet. C’est de la lumière à
                partager que de donner voix aux mots de ce grand auteur. Dans la
                famille Fernandel, c’est une tradition que l’on cultive comme on
                prend soin de ses rosiers. Une affaire de cœur et de famille,
                que de conter, parce qu’on aime passer le flambeau des mots
                aimés. Fernand fut le premier à interpréter les Lettres, puis
                vint le tour de Franck, et aujourd’hui, c’est Vincent qui
                s’empare de l’univers de Daudet dont l’enfance a été bercée. Il
                perpétue à sa manière ses œuvres préférées, en trois volumes,
                disponibles sur toutes les plateformes.
              </p>
            </motion.div>
          </div>

          <div className="pt-[100px] text-black">
            <h2 className="font-kalam text-center text-3xl max-sm:text-[25px]">
              Les Lettres De Mon Moulin par Fernandel, Franck Fernandel et
              Vincent Fernandel
            </h2>
            <div className="flex-row-reverse flex justify-center items-center gap-12 p-12 max-sm:flex-col">
              <motion.div
                variants={fadeIn("left", 0.5)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: false, amount: 0.3 }}
                className="flex flex-col items-center"
              >
                <img className="w-[300px] h-auto" src={Tale6} alt="" />
                <div className="mt-10 max-sm:text-center">
                  <a
                    className="text-white  bg-blue-500 hover:bg-blue-600 font-semibold py-2 px-4 rounded"
                    href="https://www.mariannemelodie.fr/lettres-mon-moulin-fernandel-franck-fernandel-vincent-fernandel-p2049542"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Acheter le coffret 3 CD
                  </a>
                </div>
              </motion.div>
              <motion.div
                variants={fadeIn("right", 0.5)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: false, amount: 0.3 }}
              >
                <p className="max-w-[600px] leading-9 text-[18px] text-justify font-oswald ">
                  Pour la première fois, trois générations de la famille
                  Fernandel sont réunies pour raconter Alphonse Daudet ! Un
                  magnifique coffret 3 CD qui sent bon la Provence et le Midi,
                  disponible en exclusivité chez Marianne Mélodie.
                </p>
              </motion.div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Tales;
