import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom"; // Ajoutez cette ligne

import { FaBars, FaTimes } from "react-icons/fa";
import "./NavbarStyles.css";

const Navbar = () => {
  const location = useLocation(); // Utilisez useLocation pour obtenir l'emplacement actuel

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]); // Déclenchez le défilement lorsque l'emplacement change

  // Smooth State
  const closeMenu = () => setClick(false);

  // Hamburger State
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);

  // color State
  const [color, setColor] = useState(false);
  const changeColor = () => {
    if (window.scrollY >= 100) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  // Active Scroll Navbar
  window.addEventListener("scroll", changeColor);

  return (
    <div
      className={
        color ? "bg-transparent header header-bg " : "bg-custom header"
      }
    >
      <Link to="/">
        <p className="font-oswald">Vincent Fernandel</p>
      </Link>
      <ul className={click ? "nav-menu active bg-custom " : "nav-menu "}>
        <li>
          <Link
            className="nav-item font-oswald"
            to="/"
            spy="true"
            smooth="true"
            offset={-100}
            duration={500}
            onChange={closeMenu}
          >
            ACCUEIL
          </Link>
        </li>
        <li>
          <Link
            className="nav-item font-oswald"
            to="/lesenregistrements"
            spy="true"
            smooth="true"
            offset={-100}
            duration={500}
            onChange={closeMenu}
          >
            LES ENREGISTREMENTS
          </Link>
        </li>
        <li>
          <Link
            className="nav-item font-oswald"
            to="/surscene"
            spy="true"
            smooth="true"
            offset={-100}
            duration={500}
            onChange={closeMenu}
          >
            SUR SCÈNE
          </Link>
        </li>
        <li>
          <Link
            className="nav-item font-oswald"
            to="/biographie"
            spy="true"
            smooth="true"
            offset={-100}
            duration={500}
            onChange={closeMenu}
          >
            BIOGRAPHIE
          </Link>
        </li>
        <li>
          <Link
            className="nav-item font-oswald"
            to="/medias"
            spy="true"
            smooth="true"
            offset={-100}
            duration={500}
            onChange={closeMenu}
          >
            MÉDIAS
          </Link>
        </li>
        <li>
          <Link
            className="nav-item font-oswald"
            to="/contact"
            spy="true"
            smooth="true"
            offset={-100}
            duration={500}
            onChange={closeMenu}
          >
            CONTACT
          </Link>
        </li>
      </ul>

      <div className="hamburger" onClick={handleClick}>
        {/* Si menu cliquÃ©, afficher page de fermeture */}
        {click ? (
          <FaTimes size={20} style={{ color: "fff" }} />
        ) : (
          // Sinon, afficher hamburger
          <FaBars size={20} style={{ color: "fff" }} />
        )}
      </div>
    </div>
  );
};

export default Navbar;
