import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <section
      className="font-oswald overflow-hidden text-white"
      style={{ backgroundColor: "#131114" }}
    >
      <div className="flex flex-col py-3 justify-center items-center text-[14px] h-full">
        <ul className="flex py-4 gap-8 max-lg:flex-col max-sm:flex-col items-center max-sm:m-4">
          <li>
            <p>© Vincent Fernandel 2024 </p>
          </li>
          <li>
            <Link to="/mentions">Mentions Légales</Link>
          </li>
          <li>
            <Link to="/politiquedeconfidentialite">
              Politique de confidentialité
            </Link>
          </li>
          <li>
            <p>Photos et visuels : Tous droits réservés</p>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Footer;
