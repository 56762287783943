import React from "react";
import Navbar from "../layout/navbar/Navbar";
import ImgAbout from "../assets/onscene/headeronscene.jpg";
// import ImgAbout2 from "../assets/about/aboutImg2.jpg";
import PosterOnScene from "../assets/onscene/posteronscene.jpeg";
import PictureOnScene from "../assets/onscene/pictureonscene.jpg";
import Footer from "../layout/footer/Footer";
import pdf from "../assets/pdf/pdfonscene.pdf";
import { FaFilePdf } from "react-icons/fa6";

const OnScene = () => {
  return (
    <>
      <Navbar />
      <section id="a-propos" className="h-full bg-gray-200 overflow-hidden">
        <div></div>
        <div className="flex flex-col">
          <div className="flex flex-col pt-[70px] justify-center items-center">
            <div className="relative">
              <img
                className="w-screen h-[800px] object-cover 2xl:h-[110vh] relative top-[-50px]"
                src={ImgAbout}
                alt=""
              />
              <h2
                className="text-[140px] max-xl:text-[80px] max-lg:text-[80px] max-sm:text-[35px] text-white font-oswald absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 xl:mt-10"
                style={{ textShadow: "2px 2px 4px black" }}
              >
                SUR SCÈNE
              </h2>
              <p
                className="absolute bottom-0 left-0 text-white font-oswald italic text-[18px] max-sm:text-[13px] pl-4 pb-[70px]"
                style={{ textShadow: "2px 2px 4px black" }}
              >
               © Sophie Di Malta
              </p>
            </div>

            <div className="flex flex-row-reverse gap-x-10 ">
              <div className="py-5 max-sm:mx-auto">
                <div>
                  <div className="flex gap-x-10  max-sm:flex-col-reverse pt-0 max-lg:flex-col-reverse">
                    <ul className="mt-6 text-justify flex flex-col justify-center items-center">
                      <div className="pb-8">
                        <img
                          className="w-[600px] max-sm:mx-auto max-sm:max-w-[300px]"
                          src={PosterOnScene}
                          alt=""
                        />
                      </div>
                      <div className="py-5 relative">
                        <img
                          className="w-[800px] max-sm:mx-auto max-sm:max-w-[300px]"
                          src={PictureOnScene}
                          alt=""
                        />
                        <p className="absolute bottom-0 left-0 text-white font-oswald italic text-[18px] max-sm:text-[13px] pl-3 pb-[30px]">
                          © Sophie Di Malta
                        </p>
                      </div>

                      <li className="text-[18px] leading-10">
                        <p className="max-w-[700px] max-sm:max-w-[60%] mx-auto font-oswald ">
                          Lecture musicale imaginée et mise en scène par Nicolas
                          Pagnol, <span className="font-medium	">Marcel Pagnol, variations d’amour</span> est un
                          portrait intime et ardent d’un artiste qui écrivait
                          l’amour avec le talent de ceux qui l’ont vécu. Portés
                          par la voix ensoleillée de Vincent Fernandel, les mots
                          de Pagnol rencontrent la poésie des compositions
                          pianistiques de Franck Ciup, dans un écrin tissé
                          d’extraits d’œuvres célèbres et de correspondances
                          inédites.
                        </p>
                      </li>
                      <div className="pb-5 max-sm:py-5 flex flex-row-reverse items-center">
                        <a
                          className="font-semibold text-[22px] max-sm:text-[18px] underline rounded font-oswald pl-3"
                          href={pdf}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Télécharger la plaquette du spectacle
                        </a>
                        <FaFilePdf size={25} className=" text-red-700" />
                      </div>

                      <li className="text-[18px] leading-10">
                        <p className="max-w-[700px] max-sm:max-w-[60%] mx-auto font-oswald pb-7">
                          Pour de plus amples informations, rendez-vous sur le{" "}
                          <a
                            className="font-semibold underline rounded"
                            href="https://www.marcel-pagnol.com/manifestations/lecture-musicale-marcel-pagnol-variations-damour/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            site officiel
                          </a>{" "}
                          de Marcel Pagnol
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default OnScene;
