import React, { useRef } from "react";
import ImgContact from "../assets/contact/contactwithoutbg.jpg";
import Navbar from "../layout/navbar/Navbar";
import Footer from "../layout/footer/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import emailjs from "@emailjs/browser";

const Contact = () => {
  // Email JS
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_b0qb4h3",
        "template_yjmtaxh",
        form.current,
        "pgp1hpfpOmLlkG7rz"
      )
      .then(
        (result) => {
          console.log(result.text);
          toast.success(
            "Nous vous remercions pour votre message qui sera traîté dans les meilleurs délais. Cordialement, Fanny Tempesti EGT MUSIC",
            {
              autoClose: 10000,
            }
          );
          setTimeout(() => {
            document.location.href = "/";
          }, 10000);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <>
      <Navbar />
      <section
        className="overflow-hidden pt-[70px] text-white xl:pr-[300px] h-[100%] max-sm:h-full p-5 flex flex-row-reverse 2xl:h-screen"
        style={{
          backgroundImage: `url(${ImgContact})`,
          backgroundColor: "#131114",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        id="contact"
      >
        <div className="flex flex-col justify-center max-w-[500px] max-lg:mx-auto">
          {/* text */}
          <div className="flex max-sm:pt-10 py-10">
            <div className="max-sm:flex flex-col items-center">
              <h2
                className="font-oswald text-[65px] max-sm:text-[30px] leading-none mb-6"
                style={{ textShadow: "2px 2px 4px black" }}
              >
                CONTACT
              </h2>
              <h4 className="max-w-[100%] text-[15px] max-sm:text-[13px] text-justify leading-9 text-accent font-medium mb-2 tracking-wide">
                Vincent Fernandel est représenté par Fanny Tempesti /{" "}
                <span className="uppercase">EGT Music.</span> Pour tout contact
                professionnel, merci de remplir ce formulaire.
              </h4>
              <p className="max-w-[100%] text-[15px] max-sm:text-[13px] text-justify leading-9 text-accent font-medium mb-2 tracking-wide">
                (Veuillez noter que les demandes à caractère personnel ne seront
                pas transmises)
              </p>
            </div>
          </div>
          <div className="flex justify-center items-center max-sm:flex-col">
            {/* form */}
            <form
              ref={form}
              onSubmit={sendEmail}
              className="lg:text-[16px] text-[14px] flex-1 flex flex-col items-center gap-y-2 max-sm:w-[90%]"
            >
              <input
                type="name"
                name="user_name"
                className="bg-transparent border-b py-3 outline-none w-full placeholder:text-white focus:border-accent transition-all"
                placeholder="Votre nom"
              />
              <input
                type="name"
                name="user_name"
                className="bg-transparent border-b py-3 outline-none w-full placeholder:text-white focus:border-accent transition-all"
                placeholder="Média / Société"
              />
              <input
                type="email"
                name="user_mail"
                className="bg-transparent border-b py-3 outline-none w-full placeholder:text-white focus:border-accent transition-all"
                placeholder="Votre e-mail"
                required
              />
              <textarea
                name="user_message"
                className="bg-transparent border-b py-10 outline-none w-full placeholder:text-white focus:border-accent transition-all resize-none mb-8"
                placeholder="Votre message"
                required
                minLength={10}
              ></textarea>
              <button
                type="submit"
                value="Send"
                className="btn btn-lg border-2 p-3 mb-8 hover:bg-white hover:text-black"
              >
                ENVOYER
              </button>
            </form>
          </div>
        </div>
        <ToastContainer />
      </section>
      <Footer />
    </>
  );
};

export default Contact;
