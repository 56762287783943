import React from "react";
import { Link } from "react-router-dom";
import ImgMediaNew from "../assets/medias/bannermedia.jpg";
import ImgTaleNew from "../assets/rubrics/talenew.jpeg";
import ImgAboutNew from "../assets/about/aboutnew.jpg";
import { motion } from "framer-motion";
import { fadeIn } from "../variants";

const Rubrics = () => {
  return (
    <>
      <section
        className="news py-10 h-full flex flex-col items-center overflow-hidden"
        style={{ backgroundColor: "#131114" }}
      >
        <motion.div
          variants={fadeIn("down", 0.5)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.3 }}
          className="mt-5 flex gap-10 max-sm:^flex-col max-lg:flex-col"
        >
          <Link
            to="/lesenregistrements"
            className="transition-all duration-500 bg-cover bg-center w-[400px] h-[250px] flex items-center justify-center max-lg:max-w-[90%] max-sm:max-w-[90%] mx-auto hover:scale-125 max-sm:hover:scale-100"
            style={{ backgroundImage: `url(${ImgTaleNew})` }}
          >
            <p className="text-white text-[35px] font-oswald ">
              LES ENREGISTREMENTS
            </p>
          </Link>

          <Link
            to="/biographie"
            className="transition-all duration-500 bg-cover bg-center w-[400px] h-[250px] flex items-center justify-center max-lg:max-w-[90%] max-sm:max-w-[90%] mx-auto hover:scale-125 max-sm:hover:scale-100"
            style={{ backgroundImage: `url(${ImgAboutNew})` }}
          >
            <p className="text-white text-[35px] font-oswald ">BIOGRAPHIE</p>
          </Link>
          <Link
            to="/medias"
            className="transition-all duration-500 bg-cover bg-center w-[400px] h-[250px] flex items-center justify-center max-lg:max-w-[90%] max-sm:max-w-[90%] mx-auto hover:scale-125 max-sm:hover:scale-100"
            style={{ backgroundImage: `url(${ImgMediaNew})` }}
          >
            <p className="text-white text-[35px] font-oswald ">LES MÉDIAS</p>
          </Link>
        </motion.div>
      </section>
    </>
  );
};

export default Rubrics;
