import React from "react";
import Navbar from "../layout/navbar/Navbar";
import Footer from "../layout/footer/Footer";

const Mentions = () => {
  return (
    <>
      <Navbar />
      <section
        className="pt-[100px] text-justify font-oswald text-white"
        style={{ backgroundColor: "#131114" }}
      >
        <div className="container mx-auto py-8 max-w-[600px] max-sm:max-w-[70%]">
          <h1 className="text-3xl font-bold mb-4">Mentions légales</h1>

          <div className="mb-8">
            <h2 className="text-xl font-bold mb-2">Informations générales</h2>
            <p>
              Le site www.vincent-fernandel.com est la propriété de Vincent
              Fernandel, conteur et interprète. Ce site a pour objectif de
              présenter les activités de Vincent Fernandel et de permettre aux
              utilisateurs d'écouter et d'acquérir tout matériel en rapport avec
              ses activités d'interprète.
            </p>
          </div>

          <div className="mb-8">
            <h2 className="text-xl font-bold mb-2">Responsable du site</h2>
            <p>
              Vincent Fernandel
              <br />
              SAS Vincent Fernandel Production (PARIS) <br />
            </p>
          </div>

          <div className="mb-8">
            <h2 className="text-xl font-bold mb-2">Développement du site</h2>
            <p>
              Le site a été conçu et développé par{" "}
              <a href="https://thomas-chazal.com/">Thomas Chazal</a>.<br />
              Email : thomaschazalpro@gmail.com
              <br />
              Site:{" "}
              <a className="underline" href="https://thomas-chazal.com/">
                Thomas Chazal
              </a>
            </p>
          </div>

          <div className="mb-8">
            <h2 className="text-xl font-bold mb-2">Propriété intellectuelle</h2>
            <p>
              Tous les contenus présents sur ce site, y compris les textes, les
              images, les vidéos et les contenus audios, sont la propriété de
              Vincent Fernandel ou de leurs détenteurs respectifs. Toute
              reproduction, distribution ou utilisation des contenus sans
              autorisation préalable est interdite.
            </p>
          </div>

          <div className="mb-8">
            <h2 className="text-xl font-bold mb-2">
              Liens vers des plateformes d'achat
            </h2>
            <p>
              Certains contenus présentés sur ce site sont accompagnés de
              boutons "acheter" qui redirigent vers des platesformes de vente en
              ligne, telles que [Amazon, Fnac]. Les transactions et les
              conditions d'achat sur ces platesformes sont régies par les
              conditions générales d'utilisation propres à chaque plateforme.
            </p>
          </div>

          <div className="mb-8">
            <h2 className="text-xl font-bold mb-2">Responsabilité</h2>
            <p>
              Vincent Fernandel met tout en œuvre pour s'assurer de l'exactitude
              et de la mise à jour des informations présentes sur ce site.
              Cependant, il ne peut garantir l'absence d'erreurs ou d'omissions.
              En tant qu'utilisateur, vous êtes responsable de l'utilisation que
              vous faites des informations fournies sur ce site.
            </p>
          </div>

          <div className="mb-8">
            <h2 className="text-xl font-bold mb-2">
              Modification des mentions légales
            </h2>
            <p>
              Vincent Fernandel se réserve le droit de modifier les présentes
              mentions légales à tout moment. Les modifications entrent en
              vigueur dès leur publication sur le site. Il est recommandé de
              consulter régulièrement cette page pour prendre connaissance des
              éventuelles modifications.
            </p>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Mentions;
